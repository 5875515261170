<template>
	<industry-page :detail="detail"></industry-page>
</template>

<script>
import IndustryPage from "../IndustryPage";

export default {
	name: "Geospatial",
	components: {IndustryPage},
	data() {
		return {
			detail: {
				name: "Geospatial",
				image: "/images/industries/geospatial.png",
				description: "Utilize geospatial data within your digital experience",
				example: ["AlphaBatem Labs' metaverse technology offers many benefits to the Geospatial industry, transforming how maps are created and experienced.\n" +
				"With the ability to quickly and efficiently create three-dimensional, interactive maps, the metaverse represents a major shift in the way geospatial information is represented and experienced. No longer limited to two-dimensional virtual maps or physical, three-dimensional models, the metaverse offers a new and exciting way to explore and understand geospatial data.\n"],
				benefit: {
					description: [],
					points: [
						"Creation of detailed and interactive 3D maps",
						"Faster and more efficient map creation process",
						"Lower costs compared to traditional map-making methods",
						"Improved user experience through interactive and immersive technology",
						"Opportunity to represent geospatial information in new and innovative ways.",
					]
				}
			}
		}
	}
}
</script>

<style scoped>

</style>