<template>
	<industry-page :detail="detail"></industry-page>
</template>

<script>
import IndustryPage from "../IndustryPage";

export default {
	name: "Training",
	components: {IndustryPage},
	data() {
		return {
			detail: {
				name: "Training",
				image: "/images/industries/training.png",
				description: "Everything you need to build, and grow your team on the metaverse.    ",
				example: ["The Training Industry can use AlphaBatem Labs' metaverse technology to take their training to the next level. Metaverse-based training provides a new and innovative way for employees or students to learn and improve their skills. With its immersive and multisensory experience, training in the metaverse can increase the speed and effectiveness of learning. In addition to this, the use of the metaverse for training has numerous benefits."],
				benefit: [
					"Immersive and multisensory learning experience",
					"Increased speed of learning compared to traditional methods",
					"Convenient and flexible remote access",
					"Cost-efficient with savings in time and resources",
					"Improved practicality and performance through hands-on job experience in a safe virtual setting",
				],
			}
		}
	}
}
</script>

<style scoped>

</style>