<template>
	<industry-page :detail="detail"></industry-page>
</template>

<script>
import IndustryPage from "../IndustryPage";

export default {
	name: "Automotive",
	components: {IndustryPage},
	data() {
		return {
			detail: {
				name: "Automotive",
				image: "/images/industries/automotive.png",
				description: [
					"The automotive industry is constantly evolving, with new technologies and innovations that enhance the customer experience and streamline business processes. AlphaBatem Labs' metaverse platform offers a unique opportunity for automotive companies to bring their products and services to the next level. From virtual showrooms to interactive tutorials for sales teams, the metaverse provides endless possibilities for the automotive industry to innovate and thrive."
				],
				example: [
					"Imagine you only have one physical showroom for your car company in a certain country or region. This means that potential customers would have to travel far to see what you have to offer. However, by building a virtual showroom in the metaverse, anyone can easily view your offerings without leaving their home. The interactive nature of the metaverse enhances this experience, allowing customers to see the car with different seat colours or alloys with just a click, and access details such as costs and delivery time with ease."
				],
				benefit: {
					points: [
						"Showcase your vehicles without physical location limitations: Showcase your car offerings to a wider audience without having to open a physical showroom in every country or region.",
						"Improved Customer Experience: Customers can explore your cars in detail and customise their specifications from the comfort of their own homes. They can also get information on costs and delivery time with just a click.",
						"Better Sales Process: Sales teams can refer to a metaverse walkthrough tutorial at any time to become more knowledgeable about the cars they are selling, leading to a more cognizant sales approach company-wide.",
						"Increased Sales Productivity: Onboarding new sales staff becomes smoother, resulting in increased sales productivity and more revenue.",
						"Cost-effective: Cutting costs on training new staff and making processes and employees more efficient saves time and increases productivity."
					]
				}
			}
		}
	}
}
</script>

<style scoped>

</style>