<template>
	<industry-page :detail="detail"></industry-page>
</template>

<script>
import IndustryPage from "../IndustryPage";

export default {
	name: "Retail",
	components: {IndustryPage},
	data() {
		return {
			detail: {
				name: "Retail",
				image: "/images/industries/retail.png",
				description: "Purchase goods & services through our retail shop integrations.",
				example: ["The retail industry is always searching for better ways to interact with customers. With the rise of metaverse technology, retailers can now offer a distinctive and engaging virtual shopping experience, allowing customers to communicate, play games, share content, explore, connect with brands, and make purchases. AlphaBatem Labs' metaverse platform allows retailers to create a community and strengthen their brand image in the virtual world, providing new marketing opportunities, increased engagement, and a fresh revenue stream."],
				benefit: {
					description: [],
					points: [
						"Provides an immersive and interactive shopping experience for customers",
						"Offers a virtual mall-like environment for users to discover and engage with brands",
						"Opportunities to build community and connect with customers in a virtual setting",
						"Offers a new and innovative way to showcase products and services",
						"Offers a marketing platform for brands to establish their branding expression in the virtual world",
						"Opportunities to join with virtual games to build a connection with target audience",
					]
				},
			}
		}
	}
}
</script>

<style scoped>

</style>