import { render, staticRenderFns } from "./Metaverse.vue?vue&type=template&id=022d23e3&scoped=true&"
import script from "./Metaverse.vue?vue&type=script&lang=js&"
export * from "./Metaverse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "022d23e3",
  null
  
)

export default component.exports