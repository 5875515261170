<template>
	<industry-page :detail="detail"></industry-page>
</template>

<script>
import IndustryPage from "../IndustryPage";

export default {
	name: "Architecture",
	components: {IndustryPage},
	data() {
		return {
			detail: {
				name: "Architecture",
				image: "/images/industries/architecture.png",
				description: "Reimagine and build extraordinary structures in the virtual world.",
				example: ["As an architect, traditional methods of showcasing your designs can be limited. You may present a 2D rendering on a screen or a physical model, but they lack the immersive experience that can truly bring your vision to life. "],
				benefit: {
					description: [
						"With AlphaBatem Labs, you have the power to create and present your designs in a way that goes beyond imagination. Our metaverse offers unlimited possibilities to bring your designs to life in an interactive 3D environment. Imagine walking your clients through every detail of their building, showcasing the finer points of your design, and giving them a full sensory experience. With a click of a button, you can even change the environment from a sunny day to a snowy winter, giving your clients a true sense of how their building will look and feel in different seasons. ",
						"Building in the metaverse also allows you to test your designs and make any necessary adjustments before construction begins. The immersive experience eliminates any guesswork and ensures that your designs are practical and viable.",
					],
					points: [
						"Showcase designs in an interactive 3D and immersive manner",
						"Ability to create and bring visions to life with unlimited possibilities",
						"Provide a range of different environments to showcase designs",
						"Enhance user experience and attention to detail",
						"Battle-test ideas and confirm viability before actual construction",
						"Provide clients with the ability to see designs in different seasons and environments",
						"Improve communication and understanding between clients and architects",
					]
				}
			}
		}
	}
}
</script>

<style scoped>

</style>