<template>
	<div class="industry">

		<div class="container">
			<div class="header mt-5">
				<div class="row">

					<div class="col-8">
						<h5>Industries</h5>
						<h1 class="mt-4">{{ detail.name }}</h1>

						<div v-if="typeof detail.description === 'object'" class="description animate__animated animate__fadeIn">
							<p v-for="(d,k) in detail.description" :key="k">{{ d }}</p>
						</div>
						<p v-else class="my-5 animate__animated animate__fadeIn">{{ detail.description }}</p>

						<div class="row mt-5">
							<div class="col" v-if="detail.startLink">
								<button class="btn btn-primary btn-block">Get started</button>
							</div>
							<div class="col" v-if="detail.demoLink">
								<button class="btn btn-outline-primary btn-block"><i class="fa fa-play"></i> <span class="ms-2">Watch Demo</span></button>
							</div>
						</div>
					</div>
					<div class="col-6"></div>
				</div>
			</div>
			<div class="section text-center pb-5">
				<video class="my-5" v-if="detail.video" width="100%" autoplay muted playsinline loop>
					<source :src="detail.video" type="video/mp4">
				</video>
				<img alt="" v-else class="img-hero" :src="detail.image">
				<slot></slot>
			</div>

			<div class="section my-5" v-if="detail.example !== ''">
				<h3 class="mb-4">Example use case</h3>
				<p v-for="(text,key) in detail.example" :key="key">{{ text }}</p>
			</div>

			<div class="section my-5" v-if="detail.benefit.description !== ''">
				<h3 class="mb-4 mt-5">Benefit of suggested use case</h3>
				<p v-for="(text,key) in detail.benefit.description" :key="key"> {{ text }}</p>
			</div>

			<div class="section mt-5" v-if="detail.benefit.points">
				<ul>
					<li v-for="(text,key) in detail.benefit.points" :key="key"> {{ text }}</li>
				</ul>
			</div>

			<div class="features my-5" v-if="detail.features && detail.features.length > 0">
				<h2>Use Cases In {{ name }}</h2>
				<div class="row my-5">
					<div class="col-3">
						<button @click="activeFeature = feature" class="btn btn-block mb-3" :class="feature.name === activeFeature.name ? 'btn-primary' : 'btn-outline-primary'"
								v-for="(feature,key) in detail.features"
								:key="key">{{
								feature.name
							}}
						</button>
					</div>
					<div class="col offset-1">
						<p>{{ activeFeature.description }}</p>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from "../components/Footer";

export default {
	name: "IndustryPage",
	components: {Footer},
	props: {
		detail: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			activeFeature: null
		}
	},
	beforeMount() {
		if (this.detail.features?.length > 0)
			this.activeFeature = this.features[0]
	}
}
</script>

<style scoped>
.img-hero {
	height: 75vh;
	margin: auto;
}


li {
	padding: 5px 0;
	font-weight: normal;
	color: white;
}

</style>