<template>
	<industry-page :detail="details"></industry-page>
</template>

<script>
import IndustryPage from "../IndustryPage";

export default {
	name: "DigitalSignage",
	components: {IndustryPage},
	data() {
		return {
			details: {
				name: "Advertising - Digital Signage",
				image: "/images/industries/signage.png",
				description: "In today's fast-paced and ever-changing digital world, advertisers are always looking for innovative and captivating ways to reach their target audience. With the AlphaBatem Labs metaverse platform, businesses can take their digital signage to a whole new level. The platform allows advertisers to create a metaverse experience for their customers with just a QR code and a few words. Users can scan the code on their mobile devices and be transported into a virtual world designed by the advertiser. This can be a game with rewards or simply a virtual shop representation.\n",
				example: ["The ability to create a branded virtual experience that has never been seen before is a game-changer in the advertising industry. All it takes is a creative idea and the platform to bring it to life."],
				benefit: {
					description: [
						"The marketing potential here is clearly game changing, bringing the average bystander into a completely new branded virtual experience that they have never seen before is a clear way to stand out and make a serious impact. We can make it happen, you just need to bring us an idea and some creativity!", "Digital signage employs real time smart technology in an attempt to target adverts in a more effective manner than traditional billboards. What if these digital signs also contained various QR codes that allow different people to access different advertising virtual experiences. An advert will hit home a lot more and have a much more memorable effect."
					],
					points: [
						"Creates a unique, branded virtual experience for consumers",
						"Offers interactive and engaging advertising",
						"Provides a way to increase customer engagement and interaction",
						"Offers a way to measure the effectiveness of advertising campaigns in real time",
						"Offers an opportunity for location-based advertising without physical presence",
						"Provides a way to gamify advertising and increase customer involvement",
						"Offers a new way to reward customers through virtual experiences and incentives",
					]
				}
			}
		}
	}
}
</script>

<style scoped>

</style>