<template>
	<industry-page :detail="detail"></industry-page>
</template>

<script>
import IndustryPage from "../IndustryPage";

export default {
	name: "Metaverse",
	components: {IndustryPage},
	data() {
		return {
			detail:{
				name: "Metaverse",
				image: "/images/industries/metaverse.png",
				description: "The metaverse is a digital reality that combines different aspects of technology like social media, gaming, augmented and virtual reality, and cryptocurrencies to create an immersive and interactive virtual world for users. AlphaBatem Labs is equipped to help bring any virtual experience or game to life in the metaverse, using both web2 and crypto technologies.\n",
				example: ["The broad and diverse nature of the metaverse means that it can be used for a wide range of virtual experiences and games. The possibilities are endless, from creating a metaverse for a brand or product to creating a virtual world that is entirely your own.\n"],
				benefit: {
					description: [],
					points: [
						"Increased engagement: Businesses can increase engagement with their customers by creating an immersive, interactive experience in the metaverse.",
						"Access to new markets: By expanding into the metaverse, businesses can reach new markets and customers.",
						"Enhanced brand identity: The metaverse offers unique opportunities for businesses to build and enhance their brand identity through creative and engaging experiences.",
						"Improved customer insights: Through data and analytics, businesses can gain deeper insights into customer behaviour, preferences, and needs.",
						"Cost-effectiveness: By leveraging the capabilities of the metaverse, businesses can create cost-effective experiences compared to traditional physical experiences.",
						"Competitive advantage: By being an early adopter and innovator in the metaverse, businesses can gain a competitive advantage and position themselves as thought leaders in their industry."
					],
				}
			}
		}
	}
}
</script>

<style scoped>

</style>